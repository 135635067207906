import * as React from 'react';
import { Button } from '@mui/material';
import axios from "axios"
import jwt_decode from 'jwt-decode';

export default function CustomizedMenus() {
	const membership = [
		{ id: 1, value: 'Basic' },
		{ id: 2, value: 'Plus' },
		{ id: 3, value: 'Standard' },
		{ id: 4, value: 'Premium' },
		{ id: 5, value: 'Basic' },
		{ id: 6, value: 'Plus' },
		{ id: 7, value: 'Standard' },
		{ id: 8, value: 'Premium' },
	]
	const [currentMembership, setCurrentMembership] = React.useState({})
	const [userinfo, setUserinfo] = React.useState({})
	const [loading, setLoading] = React.useState(false)

	React.useEffect(() => {
		const user = jwt_decode(localStorage.getItem('token'));
		setUserinfo(user);

		axios.post(process.env.REACT_APP_SERVER_URL + "/payment/getmembership", { email: user.email })
			.then(response => {
				console.log(888, response.data)
				setCurrentMembership(response.data)
				setTimeout(() => {
					setLoading(true)
				}, 100);
			})
			.catch(error => {
				console.error(error);
			});
	}, [])

	return (
		<div>
			<Button
				id="demo-customized-button"
				aria-haspopup="true"
				variant="contained"
				disableElevation
			>
				{/* {currentMembership.freetrial === 0 && ('Free trial' + (userinfo?.freeprompt && ` - ${currentMembership.free} prompts`))} */}
				{/* {loading && currentMembership.data.freetrial === 1 ? */}
				{loading &&
					(currentMembership.flag === 0 ?
						'Basic'
						:
						(currentMembership.data.freetrial === 0 ? membership[currentMembership.data.ptype - 1].value + ' Plan' : 'Free trial')
					)}
			</Button>
		</div>
	);
}
