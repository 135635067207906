import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import jwt_decode from 'jwt-decode';

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  if (localStorage.getItem('token')) {
    if (jwt_decode(localStorage.getItem('token')).email !== "unknown@gmail.com") {
      // window.location.href = '/'
      return <Navigate to="/" />;
    }
  }

  return <>{children}</>;
}
