import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container, Menu, MenuItem, Avatar } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import PaymentIcon from '@mui/icons-material/Payment';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name = " ") {
  return {
    sx: {
      bgcolor: stringToColor(name),
      color: '#fff'
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const navigate = useNavigate();
  const userinfo = jwt_decode(localStorage.getItem('token'));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const [user, setUser] = useState({});

  useEffect(() => {
    setUser(jwt_decode(localStorage.getItem('token')));
  }, [])

  const handleLogout = async () => {
    localStorage.removeItem('token');
    // eslint-disable-next-line no-restricted-globals
    window.location.reload();
  };

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '0.975rem !important'
          }}
        >
          {/* <RouterLink to="/">
            <Logo />
          </RouterLink> */}
          <a href="https://tekia.ai">
            <Logo />
          </a>
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>

          {user.email === "unknown@gmail.com" &&
            <>
              <Button variant="contained" component={RouterLink} to="/auth/login" sx={{ mr: 2 }}>
                Sign in
              </Button>

              <Button variant="contained" component={RouterLink} to="/auth/register/index/er3fgReSnKJu">
                Sign up
              </Button>
            </>
          }

          {user.email !== "unknown@gmail.com" &&
            <>
              {userinfo.username !== "unknown@gmail.com" &&
                <Avatar
                  sx={{ cursor: 'pointer' }}
                  {...stringAvatar(userinfo.username)}
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                />
              }

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{ color: '#e3e3e3' }}
              >
                <MenuItem onClick={() => { navigate(`/dashboard/user/account`) }}>
                  <Box className='settingIcon' component='img' src='/static/icons/setting3.svg' />
                  Setting
                </MenuItem>
                <MenuItem onClick={() => { navigate(`/dashboard/payment/history`) }}>
                  <PaymentIcon className='settingIcon' sx={{ width: '1.2rem' }} />
                  Payment
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <Box className='settingIcon' component='img' src='/static/icons/setting1.svg' />
                  Logout
                </MenuItem>
              </Menu>
            </>
          }

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
