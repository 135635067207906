import PropTypes from 'prop-types';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', ...sx }}>
      <img id='logo1' src='/static/logo.png' alt="Tekia.ai Logo - Advanced AI-Powered Data Analysis" />
    </Box>
  );
}
